import { RefObject, useEffect, useRef, useState } from "react"

let timer

type Args = {
  trackRef: RefObject<HTMLElement | undefined>
  scrollAmount?: number | "full-width"
}

export type UseSliderPayload = {
  next: () => void
  prev: () => void
  goToIndex?: (newIndex: number) => void
  onTouchEnd: (event: React.TouchEvent<HTMLElement>) => void
  forceRevalidate: () => void
  canMoveLeft: boolean
  canMoveRight: boolean
  index: number
}

export function useSlider({
  trackRef,
  scrollAmount = 1,
}: Args): UseSliderPayload {
  const [canMoveLeft, setCanMoveLeft] = useState(false)
  const [canMoveRight, setCanMoveRight] = useState(false)
  const [index, setIndex] = useState(0)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const forceRevalidate = () => {
    revalidate()
  }

  const prev = () => {
    if (canMoveLeft && trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.scrollLeft - trackRef.current.offsetWidth
          : trackRef.current.scrollLeft - itemWidth * scrollAmount
      scroll(left)

      if (scrollAmount !== "full-width") {
        setIndex(index - scrollAmount)
      }
    }
  }
  const next = () => {
    if (canMoveRight && trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.scrollLeft + trackRef.current.offsetWidth
          : trackRef.current.scrollLeft + itemWidth * scrollAmount

      scroll(left)
      if (scrollAmount !== "full-width") {
        setIndex(index + scrollAmount)
      }
    }
  }

  const goToIndex = (newIndex: number) => {
    if (trackRef.current) {
      const itemWidth = (trackRef.current.children[0] as HTMLElement)
        .offsetWidth
      const left =
        scrollAmount === "full-width"
          ? trackRef.current.offsetWidth
          : itemWidth * newIndex
      scroll(left)
      if (scrollAmount !== "full-width") {
        setIndex(newIndex)
      }
    }
  }
  const onTouchEnd = () => {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      if (trackRef && trackRef.current && scrollAmount !== "full-width") {
        const closestIndex = getClosestIndex(trackRef.current)
        setIndex(closestIndex)
      }
    }, 500)
  }

  const revalidate = () => {
    if (observerRef && observerRef.current) {
      ;(observerRef.current as IntersectionObserver).disconnect()
    }

    const children = trackRef?.current
      ? Array.from(trackRef.current.children)
      : []
    const firstChild = children[0]
    const lastChild = children[children.length - 1]
    // Check if the first or last child is or isn't intersecting.
    // If it is, it means we cant scroll further to the left or right
    const observe = (entries) => {
      entries.forEach((entry) => {
        if (entry.target === firstChild) {
          setCanMoveLeft(!entry.isIntersecting)
        }
        if (entry.target === lastChild) {
          setCanMoveRight(!entry.isIntersecting)
        }
      })
    }

    // Create a new IntersectionObserver and observe the first and the last child
    observerRef.current = new IntersectionObserver(observe, {
      root: trackRef.current,
      rootMargin: "0px",
      threshold: 0.9,
    })

    if (firstChild) {
      observerRef.current.observe(firstChild)
    }
    if (lastChild) {
      observerRef.current.observe(lastChild)
    }
  }

  const scroll = (left: number) =>
    trackRef?.current &&
    trackRef.current.scrollTo({ top: 0, left, behavior: "smooth" })

  useEffect(() => {
    revalidate()
    // Clear the timer if set
    return () => {
      clearTimeout(timer)
    }
  }, [trackRef]) // eslint-disable-line

  return {
    next,
    prev,
    goToIndex,
    onTouchEnd,
    forceRevalidate,
    canMoveLeft,
    canMoveRight,
    index,
  }
}

function getClosestIndex(element: HTMLElement): number {
  const parentRect = element.getBoundingClientRect()

  // Creates an array with the relative distances to the parent e.g. [390, -2, -396]
  // The closest can be -2 or -10 or something instead of 0, because scrolling can still be underway due to snap point
  const distances = Array.from(element.children).map((child, index) =>
    Math.round(parentRect.x - child.getBoundingClientRect().x),
  )
  // Get the index of the value that's closest to 0. E.g. [390, -2, -396].indexOf(-2) === 1
  const closestIndex = distances.indexOf(
    distances.reduce((prev, curr) =>
      Math.abs(curr - 0) < Math.abs(prev - 0) ? curr : prev,
    ),
  )
  return closestIndex
}
