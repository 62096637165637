import { Container } from "@components/ui/Container"
import { HeadingLink } from "@components/ui/heading-link"
import { Heading } from "@components/ui/typograhpy/Heading"
import Link from "next/link"
import { FC } from "react"

type LandingPageHeroTextAndButtonsProps = {
  title: string
}

export const LandingPageHeroTextAndButtons: FC<
  LandingPageHeroTextAndButtonsProps
> = ({ title }) => {
  return (
    <Container
      className="max-w-[34rem] pb-16 pt-20 text-center md:pt-24 lg:max-w-[45rem] lg:pb-24 lg:pt-40 xl:max-w-[58rem]"
      as="div"
    >
      <Heading variant="h1" as="h1" className="mb-6 md:mb-8">
        {title}
      </Heading>
      <div className="flex flex-col items-center justify-center gap-2 md:flex-row md:gap-8">
        <Link href="/signup/create-account" passHref legacyBehavior>
          <HeadingLink as="link" variant="internal" color="gradient" size="h3">
            Try for free
          </HeadingLink>
        </Link>
        <HeadingLink
          as="link"
          variant="external"
          color="grey"
          size="h3"
          href="https://docs.viesus.cloud"
          target="blank"
        >
          Read the docs
        </HeadingLink>
      </div>
    </Container>
  )
}
