import { cn } from "@lib/cn"
import * as Dialog from "@radix-ui/react-dialog"
import { m } from "framer-motion"
import React, { FC, ReactNode } from "react"
import { IconButton } from "../button/IconButton"

export type OverlayProps = {
  title: string
  headerChild?: React.ReactElement
  trigger?: React.ReactElement
  leftHeaderButton?: React.ReactElement
  animateFrom?: "bottom" | "right"
  onOpenChange?: (open: boolean) => void
  hideHeaderBorders?: boolean
  open?: boolean
  children?: ReactNode
}

export const Overlay: FC<OverlayProps> = ({
  trigger,
  title,
  headerChild,
  children,
  leftHeaderButton,
  animateFrom = "right",
  open,
  onOpenChange,
  hideHeaderBorders = false,
}) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {trigger && <Dialog.Trigger asChild>{trigger}</Dialog.Trigger>}
      <Dialog.Portal>
        <Dialog.Overlay className="bg-background/0.7 fixed inset-0 z-100 h-full w-full animate-fade-in" />
        <Dialog.Content
          className={cn(
            "fixed right-0 top-0 z-101 h-full w-full bg-sidebar md:max-w-96",
            "data-[state=open]:animate-overlay-from-right",
            "data-[state=closed]:animate-overlay-to-right",
            animateFrom === "bottom" &&
              "md:data-[state=closed]:animate-overlay-to-bottom md:data-[state=open]:animate-overlay-from-bottom",
          )}
          data-animate-from={animateFrom}
        >
          <aside className="relative grid h-full w-full grid-cols-[1fr] grid-rows-[auto_1fr]">
            <div
              className={cn(
                "border-b border-b-border text-center",
                hideHeaderBorders && "border-b-0",
              )}
              data-hide-border={hideHeaderBorders ? "" : undefined}
            >
              <div
                className={cn(
                  "flex items-center justify-start gap-4 border-b border-b-border px-5 py-4",
                  hideHeaderBorders && "border-b-0",
                )}
                data-hide-border={
                  headerChild || hideHeaderBorders ? "" : undefined
                }
              >
                {leftHeaderButton && leftHeaderButton}
                <m.div
                  layout
                  transition={{
                    duration: 0.15,
                  }}
                >
                  <h5 className="text-xl font-extrabold leading-1.35 tracking-tight">
                    {title}
                  </h5>
                </m.div>
                <Dialog.Close className="ml-auto">
                  <IconButton
                    as="span"
                    size="small"
                    variant="secondary"
                    icon="close"
                    label={`Close ${title}`}
                  />
                </Dialog.Close>
              </div>
              {headerChild && (
                <div className="mb-5 px-5 py-1">{headerChild}</div>
              )}
            </div>
            <div className="min-h-[calc(100%-4.5rem)] overflow-scroll">
              {children}
            </div>
          </aside>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
