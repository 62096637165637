import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { LandingPageEnhancementProcessDot } from "./Dot"
import { cn } from "@lib/cn"

type LandingPageEnhancementProcessItemProps =
  HomepageSingleton["fields"]["process"]["steps"][0] & {
    isFirst: boolean
  }

export const LandingPageEnhancementProcessItem: FC<
  LandingPageEnhancementProcessItemProps
> = ({ uspTitle, uspDescription, stepImage, detailImage, isFirst }) => {
  const { width: stepWidth, height: stepHeight } = getSanityImageDimensions(
    stepImage.asset._ref,
  )
  const { width: detailWidth, height: detailHeight } = getSanityImageDimensions(
    detailImage.asset._ref,
  )

  return (
    <div className={cn(
      "flex lg:grid relative items-center pt-0 lg:pt-14 pb-12 lg:pb-14 flex-col-reverse gap-6 mx-auto max-w-[35rem]",
      "lg:grid-cols-[1fr_1fr] lg:gap-x-24 lg:max-w-[initial] xl:gap-x-40"
    )}>
      <div className="relative">
        <div className="flex border border-border rounded-xl overflow-hidden shadow-medium">
          <Image
            src={urlFor(stepImage).auto("format").width(800).toString()}
            alt="Main step image"
            height={stepHeight}
            width={stepWidth}
          />
        </div>
        <div className="absolute right-6 -bottom-6">
          <Image
            src={urlFor(detailImage).auto("format").width(800).toString()}
            alt="Step detail image"
            layout="intrinsic"
            height={detailHeight}
            width={detailWidth}
            className="w-auto !h-11"
          />
        </div>
      </div>
      <div className="flex items-center lg:items-start justify-center lg:justify-start text-center lg:text-left flex-col">
        <Heading variant="h4" as="h4" className="mb-3">
          {uspTitle}
        </Heading>
        <Text variant="large" className="mb-0">
          {uspDescription}
        </Text>
      </div>
      <div
        className={cn(
          "hidden lg:flex absolute items-center h-full left-1/2 -translate-x-1/2",
          "before:content-[''] before:bg-border before:w-[3px] before:h-full before:absolute before:left-1/2 before:-translate-x-1/2 z-[0]",
          "data-[is-first]:after:block data-[is-first]:after:top-0 data-[is-first]:after:content-[''] data-[is-first]:after:w-4 data-[is-first]:after:h-20 data-[is-first]:after:absolute data-[is-first]:after:left-1/2",
          "data-[is-first]:after:-translate-x-1/2 data-[is-first]:after:z-[1] data-[is-first]:after:bg-chain-gradient"
        )}
        data-is-first={isFirst ? "" : undefined}
      >
        <LandingPageEnhancementProcessDot />
      </div>
      <div className={cn(
        "flex lg:hidden items-end relative h-[4.5rem] before:content-[''] before:bg-border before:w-[3px]",
        "before:h-full before:absolute before:left-1/2 before:-translate-x-1/2 before:z-[0]",
        "after:block after:top-0 after:content-[''] after:w-4 after:h-12 after:absolute after:left-1/2 after:-translate-x-1/2 after:z-[1] after:bg-chain-gradient"
      )}>
        <LandingPageEnhancementProcessDot />
      </div>
    </div>
  )
}
