import { Container } from "@components/ui/Container"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { ContentPageSectionTitle } from "../../content-page/SectionTitle"
import { LandingPageSuccessStoriesSlider } from "./Slider"

type LandingPageSuccessStoriesProps =
  HomepageSingleton["fields"]["successStories"]

export const LandingPageSuccessStories: FC<LandingPageSuccessStoriesProps> = ({
  title,
  description,
  slides,
}) => {
  return (
    <section className="mb-[7.5rem]">
      <Container size="medium" as="div">
        <ContentPageSectionTitle title={title} description={description} />
      </Container>
      <LandingPageSuccessStoriesSlider slides={slides} />
    </section>
  )
}
