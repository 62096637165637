import { cn } from "@lib/cn"
import React, { FC, HTMLAttributes } from "react"

export type IconProps = HTMLAttributes<SVGElement> & {
  size?: number
  fill?: string
}

export const Icon: FC<IconProps> = ({ size = 24, className, ...rest }) => {
  return (
    <svg
      vectorEffect="non-scaling-stroke"
      viewBox="0 0 32 32"
      className={cn("vector-effect-non-scaling-stroke", className)}
      fill="none"
      width={size}
      height={size}
      {...rest}
    />
  )
}
