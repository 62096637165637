import { Container } from "@components/ui/Container"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"

type LandingPageTestimonialsProps = HomepageSingleton["fields"]["testimonials"]

export const LandingPageTestimonials: FC<LandingPageTestimonialsProps> = ({
  testimonialItems,
}) => {
  return (
    <Container size="medium" className="grid grid-cols-[1fr] gap-y-16 mb-[7.5rem] md:mb-44 md:grid-cols-[1fr_1fr] md:gap-x-6 lg:mb-[12.5rem] lg:gap-x-8">
      {testimonialItems.map((testimonial) => {
        const { width: logoWidth, height: logoHeight } =
          getSanityImageDimensions(testimonial.testimonialItemLogo.asset._ref)
        const { width: avatarWidth, height: avatarHeight } =
          getSanityImageDimensions(testimonial.testimonialItemAvatar.asset._ref)

        return (
          <div className="flex flex-col" key={testimonial._key}>
            <div className="flex mb-8 max-h-8">
              <Image
                src={urlFor(testimonial.testimonialItemLogo)
                  .auto("format")
                  .width(300)
                  .toString()}
                alt={testimonial.testimonialItemCustomerName}
                height={logoHeight}
                width={logoWidth}
                className="!w-auto !h-8"
              />
            </div>
            <Text variant="special" className="mb-8">
              {testimonial.testimonialItemContent}
            </Text>
            <div className="grid grid-cols-[4rem_1fr] gap-6 mt-auto">
              <div className="flex mb-8 w-16 h-16 rounded-[50%] overflow-hidden">
                <Image
                  src={urlFor(testimonial.testimonialItemAvatar)
                    .auto("format")
                    .width(120)
                    .toString()}
                  alt={testimonial.testimonialItemCustomerName}
                  height={avatarHeight}
                  width={avatarWidth}
                  className="!h-16"
                />
              </div>
              <div className="mt-2">
                <Heading variant="h6" as="h5" className="mb-0">
                  {testimonial.testimonialItemCustomerName}
                </Heading>
                <Text variant="large" className="text-shade-400">
                  {testimonial.testimonialItemCustomerJobTitle}
                </Text>
              </div>
            </div>
          </div>
        )
      })}
    </Container>
  )
}
