import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"

import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { cn } from "@lib/cn"

type BlockType =
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["firstBigFeatureBlock"]
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["secondBigFeatureBlock"]

type LandingPageFeaturesBigFeatureProps = BlockType & {
  variant: "text-image" | "image-text"
}

export const LandingPageFeaturesBigFeature: FC<
  LandingPageFeaturesBigFeatureProps
> = ({
  variant,
  title,
  description,
  mobileImage,
  desktopImage,
  showAiLabel,
}) => {
  const { width: mobileWidth, height: mobileHeight } = getSanityImageDimensions(
    mobileImage.asset._ref,
  )
  const { width: desktopWidth, height: desktopHeight } =
    getSanityImageDimensions(desktopImage.asset._ref)

  return (
    <div
      className={cn(
        "grid bg-border-disabled overflow-hidden rounded-xl grid-cols-[1fr] gap-0",
        "md:col-span-2 md:grid-cols-[4fr_6fr] md:data-[variant='image-text']:grid-cols-[6fr_4fr]",
        "md:data-[variant='image-text']:data-[images]:col-span-1 md:data-[variant='image-text']:data-[images]:row-span-1",
        "xl:grid-cols-[1fr_2fr] xl:data-[variant='image-text']:grid-cols-[2fr_1fr]"
      )}
      data-variant={variant}
    >
      <div className="py-10 px-6 sm:px-8 lg:px-10">
        {showAiLabel && <span className="py-1 px-2 bg-border inline-block mb-4 rounded-md border border-white">AI</span>}
        <Heading variant="h4" as="h3" className="mb-4">
          {title}
        </Heading>
        <Text variant="large" className="mb-0">
          {description}
        </Text>
      </div>
      <div data-images>
        <div className="flex lg:hidden">
          <Image
            src={urlFor(mobileImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={mobileHeight}
            width={mobileWidth}
          />
        </div>
        <div className="hidden lg:flex">
          <Image
            src={urlFor(desktopImage).auto("format").width(1200).toString()}
            alt="Hero main image"
            height={desktopHeight}
            width={desktopWidth}
          />
        </div>
      </div>
    </div>
  )
}
