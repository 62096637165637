import { Text } from "@components/ui/typograhpy/Text"
import { FC } from "react"

export const LandingPageHeroStats: FC = () => {
  return (
    <div className="absolute grid grid-cols-[1fr_1fr] gap-3 translate-y-[80%] bottom-0 left-[7.5rem]">
      <div className="py-2 lg:py-3 px-3 lg:px-4 bg-border rounded-xl shadow-stat">
        <Text variant="micro" className="text-shade-400">
          Uploads
        </Text>
        <Text variant="special">26.372</Text>
      </div>
      <div className="py-2 lg:py-3 px-3 lg:px-4 bg-border rounded-xl shadow-stat">
        <Text variant="micro" className="text-shade-400">
          Enhancements
        </Text>
        <Text variant="special">23.947</Text>
      </div>
    </div>
  )
}
