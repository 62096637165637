import React, { FC } from "react"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"

type ContentPageSectionTitleProps = {
  title: string
  description: string
}

export const ContentPageSectionTitle: FC<ContentPageSectionTitleProps> = ({
  title,
  description,
}) => {
  return (
    <div className="flex items-center justify-center text-center w-full max-w-[42rem] mr-auto ml-auto flex-col mb-16 md:mb-20 lg:mb-24">
      <Heading
        variant="h2"
        as="h2"
        className="mb-6 md:mb-8 whitespace-pre-line"
      >
        {title}
      </Heading>
      <Text variant="special">{description}</Text>
    </div>
  )
}
