import { HeadingLink } from "@components/ui/heading-link"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import Link from "next/link"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { cn } from "@lib/cn"

export type LandingPageSuccessStoriesItemProps =
  HomepageSingleton["fields"]["successStories"]["slides"][0] & {
    position: "first" | "middle" | "last"
  }

export const LandingPageSuccessStoriesItem: FC<
  LandingPageSuccessStoriesItemProps
> = ({ slideDescription, slideImage, slideTitle, link, position }) => {
  return (
    <div
      className={cn(
        "flex min-w-[calc(100%-2.5rem)] gg:min-w-[1088px]",
        position === "first" && "ml-[calc(50vw-(100%-2.5rem)/2)] gg:ml-[calc(50vw-544px)]"
      )}
      data-position={position}
    >
      <div className={cn(
        "bg-border-disabled grid w-full grid-cols-[1fr] grid-rows-[auto_1fr] rounded-xl overflow-hidden",
        "md:min-h-[35vh] md:grid-cols-[1fr_1fr] md:grid-rows-[1fr] xl:grid-cols-[1fr_2fr]"
      )}>
        <div className="py-8 md:py-10 px-6 md:px-8 lg:px-10 flex-[1]">
          <Heading variant="h4" as="h4" className="mb-4">
            {slideTitle}
          </Heading>
          <Text variant="large" className="mb-0">
            {slideDescription}
          </Text>
          {link && link.href && link.text && link.type && (
            <>
              {link.type === "external" ? (
                <HeadingLink
                  className="mt-4"
                  as="link"
                  href={link.href}
                  target="_blank"
                  variant="external"
                  size="h6"
                  color="gradient"
                >
                  {link.text}
                </HeadingLink>
              ) : (
                <Link href={link.href} passHref legacyBehavior>
                  <HeadingLink
                    className="mt-4"
                    as="link"
                    variant="internal"
                    size="h6"
                    color="gradient"
                  >
                    {link.text}
                  </HeadingLink>
                </Link>
              )}
            </>
          )}
        </div>
        <div className="relative w-full h-full pt-[56.25%] row-span-1 col-span-1 md:row-auto md:col-auto">
          <Image
            src={urlFor(slideImage).auto("format").width(1500).toString()}
            alt="Hero main image"
            layout="fill"
            objectFit="cover"
          />
        </div>
      </div>
    </div>
  )
}
