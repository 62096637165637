import { HeadingLink } from "@components/ui/heading-link"
import { cn } from "@lib/cn"
import Link from "next/link"
import React, { FC } from "react"
import { LandingPageEnhancementProcessDot } from "./Dot"

export const LandingPageEnhancementProcessButtons: FC = () => {
  return (
    <div className="relative mb-[7.5rem] flex flex-col items-center justify-center pt-12 text-center md:mb-44 lg:mb-[12.5rem] lg:pt-14">
      <div
        className={cn(
          "mb-8 flex items-center before:absolute before:left-1/2 before:top-0 before:h-12 before:w-[3px] before:bg-border before:content-['']",
          "before:z-[0] before:-translate-x-1/2 before:lg:h-14",
          "after:absolute after:left-1/2 after:top-0 after:z-[1] after:block after:h-8 after:w-4 after:-translate-x-1/2 after:content-['']",
          "after:bg-chain-gradient after:lg:hidden",
        )}
      >
        <LandingPageEnhancementProcessDot />
      </div>
      <Link href="/signup" passHref legacyBehavior>
        <HeadingLink variant="internal" color="gradient" size="h3">
          Get started with the API
        </HeadingLink>
      </Link>
      <HeadingLink
        href="https://viesus.com"
        target="_blank"
        variant="external"
        color="grey"
        className="mb-2"
        size="h3"
      >
        Our standalone app or SDK
      </HeadingLink>
    </div>
  )
}
