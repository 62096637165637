import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"
import { SanityImage } from "@lib/sanity/image-type"
import Image from "next/image"
import { FC } from "react"

type LandingPageHeroImagesProps = {
  mainImage: SanityImage
  codeImage: SanityImage
}

export const LandingPageHeroImages: FC<LandingPageHeroImagesProps> = ({
  mainImage,
  codeImage,
}) => {
  const { width: mainWidth, height: mainHeight } = getSanityImageDimensions(
    mainImage.asset._ref,
  )
  const { width: codeWidth, height: codeHeight } = getSanityImageDimensions(
    codeImage.asset._ref,
  )
  return (
    <>
      <div className="px-4 md:px-0 -mr-5 -ml-5 overflow-auto md:mr-0 md:ml-0">
        <div className="w-[200vw] border border-border rounded-xl overflow-hidden shadow-scroll md:flex md:w-[initial]">
          <Image
            src={urlFor(mainImage).auto("format").width(1500).toString()}
            alt="Hero main image"
            height={mainHeight}
            width={mainWidth}
          />
        </div>
      </div>
      <div className="absolute max-w-80 -bottom-4 left-0 shadow-code-container pointer-events-none lg:-left-10 xl:-left-16 xl:max-w-[25rem]">
        <Image
          src={urlFor(codeImage).auto("format").width(1500).toString()}
          alt="Hero main image"
          height={codeHeight}
          width={codeWidth}
        />
      </div>
    </>
  )
}
