import { Container } from "@components/ui/Container"
import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { urlFor } from "@lib/sanity"
import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { HeadingLink } from "@components/ui/heading-link"
import Link from "next/link"
import { cn } from "@lib/cn"

type LandingPageUspsProps = HomepageSingleton["fields"]["usps"]

export const LandingPageUsps: FC<LandingPageUspsProps> = ({ usps }) => {
  return (
    <Container size="medium" className="mb-44 lg:mb-[12.5rem]">
      <div className="grid mb-16 lg:mb-24 grid-cols-[1fr] gap-8 lg:grid-cols-[1fr_1fr]">
        {usps.map((usp) => (
          <div
            className={cn(
              "grid items-start lg:items-center grid-cols-[3.75rem_1fr] gap-4",
              "md:grid-cols-[4.5rem_1fr] md:gap-8 lg:grid-cols-[6rem_1fr] xl:grid-cols-[6.5rem_1fr] xl:first-letter:gap-9"
            )}
            key={usp._key}
          >
            <div className="relative">
              <Image
                src={urlFor(usp.uspIcon).auto("format").width(150).toString()}
                alt={usp.uspTitle}
                height={120}
                width={120}
              />
            </div>
            <div className="-mt-1">
              <Heading variant="h6" as="h4" className="mb-0">
                {usp.uspTitle}
              </Heading>
              <Text variant="large" className="mb-0">
                {usp.uspDescription}
              </Text>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center flex-col gap-2 md:flex-row md:gap-8">
        <Link href="/app" passHref legacyBehavior>
          <HeadingLink color="gradient" variant="internal" size="h3">
            Start enhancing
          </HeadingLink>
        </Link>
        <HeadingLink
          color="grey"
          variant="external"
          target="_blank"
          size="h3"
          href="https://viesus.com"
        >
          More about Viesus
        </HeadingLink>
      </div>
    </Container>
  )
}
