import { Container } from "@components/ui/Container"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { ContentPageSectionTitle } from "../../content-page/SectionTitle"
import { LandingPageFeaturesBigFeature } from "./BigFeature"
import { LandingPageFeaturesSmallFeature } from "./SmallFeature"

type LandingPageFeaturesProps = HomepageSingleton["fields"]["features"]

export const LandingPageFeatures: FC<LandingPageFeaturesProps> = ({
  title,
  description,
  featureBlocks,
}) => {
  return (
    <Container size="medium">
      <ContentPageSectionTitle title={title} description={description} />
      <div className="grid mb-16 lg:mb-24 grid-cols-[1fr] gap-8 md:grid-cols-[1fr_1fr] md:gap-x-6 lg:gap-x-8 lg:gap-y-10">
        <LandingPageFeaturesBigFeature
          variant="text-image"
          {...featureBlocks.firstBigFeatureBlock}
        />
        <LandingPageFeaturesSmallFeature
          {...featureBlocks.firstSmallFeatureBlock}
        />
        <LandingPageFeaturesSmallFeature
          {...featureBlocks.secondSmallFeatureBlock}
        />
        <LandingPageFeaturesBigFeature
          variant="image-text"
          {...featureBlocks.secondBigFeatureBlock}
        />
      </div>
    </Container>
  )
}
