import { Heading } from "@components/ui/typograhpy/Heading"
import { Text } from "@components/ui/typograhpy/Text"
import { getSanityImageDimensions } from "@lib/get-sanity-image-dimensions"
import { urlFor } from "@lib/sanity"

import Image from "next/image"
import React, { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { HeadingLink } from "@components/ui/heading-link"
import Link from "next/link"

type BlockType =
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["firstSmallFeatureBlock"]
  | HomepageSingleton["fields"]["features"]["featureBlocks"]["secondSmallFeatureBlock"]

type LandingPageFeaturesSmallFeatureProps = BlockType

export const LandingPageFeaturesSmallFeature: FC<
  LandingPageFeaturesSmallFeatureProps
> = ({
  title,
  description,
  mobileImage,
  desktopImage,
  showComingSoon,
  link,
}) => {
  const { width: mobileWidth, height: mobileHeight } = getSanityImageDimensions(
    mobileImage.asset._ref,
  )
  const { width: desktopWidth, height: desktopHeight } =
    getSanityImageDimensions(desktopImage.asset._ref)

  return (
    <div className="flex bg-border-disabled flex-col justify-between overflow-hidden rounded-xl">
      <div className="py-10 px-8 lg:px-10 mb-8 lg:mb-10">
        <Heading variant="h4" as="h3" className="mb-4">
          {title}
        </Heading>
        <Text variant="large" className="mb-0">
          {description}
        </Text>
        {showComingSoon && <span className="py-1 px-2 bg-border inline-block mt-4 rounded-[2rem] border border-white">Coming soon</span>}
        {link && link.href && link.text && link.type && (
          <>
            {link.type === "external" ? (
              <HeadingLink
                className="mt-4"
                as="link"
                href={link.href}
                target="_blank"
                variant="external"
                size="h6"
                color="gradient"
              >
                {link.text}
              </HeadingLink>
            ) : (
              <Link href={link.href} passHref legacyBehavior>
                <HeadingLink
                  className="mt-4"
                  as="link"
                  variant="internal"
                  size="h6"
                  color="gradient"
                >
                  {link.text}
                </HeadingLink>
              </Link>
            )}
          </>
        )}
      </div>
      <div>
        <div className="flex lg:hidden pl-8 lg:pl-10">
          <Image
            src={urlFor(mobileImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={mobileHeight}
            width={mobileWidth}
          />
        </div>
        <div className="hidden lg:flex pl-8 lg:pl-10">
          <Image
            src={urlFor(desktopImage).auto("format").width(800).toString()}
            alt="Hero main image"
            height={desktopHeight}
            width={desktopWidth}
          />
        </div>
      </div>
    </div>
  )
}
