import { Container } from "@components/ui/Container"
import { FC } from "react"
import { HomepageSingleton } from "__generated__/sanity"
import { LandingPageHeroImages } from "./Images"
import { LandingPageHeroStats } from "./Stats"
import { LandingPageHeroTextAndButtons } from "./TextAndButtons"

type LandingPageHeroProps = HomepageSingleton["fields"]["hero"]

export const LandingPageHero: FC<LandingPageHeroProps> = ({
  title,
  mainImage,
  codeImage,
}) => {
  return (
    <section className="mb-40 md:mb-60 lg:mb-[17.5rem]">
      <LandingPageHeroTextAndButtons title={title} />
      <Container
        size="medium"
        className="relative"
        as="div"
      >
        <LandingPageHeroImages mainImage={mainImage} codeImage={codeImage} />
        <LandingPageHeroStats />
      </Container>
    </section>
  )
}
