import { cn } from "@lib/cn"
import * as VisuallyHidden from "@radix-ui/react-visually-hidden"
import React, { ButtonHTMLAttributes } from "react"
import { getBasicButtonClass } from "./styles"

type CleanIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: "grey-to-white" | "white-to-white"
  label: string
}

export const CleanIconButton = React.forwardRef<
  HTMLButtonElement,
  CleanIconButtonProps
>(({ children, className, variant = "grey-to-white", label, ...rest }, ref) => (
  <button
    className={cn(
      getBasicButtonClass(),
      "relative p-1 disabled:pointer-events-none disabled:opacity-50",
      variant === "grey-to-white" && "text-shade-500 hover:text-white",
      variant === "white-to-white" && "text-white hover:text-white",
      className,
    )}
    data-variant={variant}
    ref={ref}
    {...rest}
  >
    <VisuallyHidden.Root>{label}</VisuallyHidden.Root>
    {children}
  </button>
))
CleanIconButton.displayName = "CleanIconButton"
